export const sportList = [
  "Acrobatics and Tumbling",
  "archery",
  "badminton",
  "baseball",
  "basketball",
  "Beach Volleyball",
  "bowling",
  "cheerleading",
  "crew",
  "cross-country running",
  "equestrian sports",
  "fencing",
  "field hockey",
  "football",
  "golf",
  "gymnastics",
  "ice hockey",
  "indoor track",
  "lacrosse",
  "racquetball",
  "riflery",
  "rock climbing",
  "rowing",
  "rugby",
  "sailing",
  "sand volleyball",
  "skiing (cross-country)",
  "skiing (downhill)",
  "soccer",
  "softball",
  "squash",
  "swimming and diving",
  "table tennis",
  "tennis",
  "track and field",
  "triathlon",
  "ultimate Frisbee",
  "volleyball",
  "water polo",
  "weight lifting",
  "wrestling",
];
